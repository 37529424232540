@font-face {
  font-family: "Gridnik";
  src: url("/fonts/Foundry Gridnik W03 Regular.ttf");
  font-weight: 400; }

@font-face {
  font-family: "Gridnik";
  src: url("/fonts/Foundry Gridnik W03 Medium.ttf");
  font-weight: 500; }

@font-face {
  font-family: "LibreBaskerville";
  src: url("/fonts/LibreBaskerville-Regular.ttf");
  font-weight: 400; }

@font-face {
  font-family: "circe-slab-b";
  src: url("/fonts/Circe Slab_A Regular.ttf");
  font-weight: 400; }

::selection {
  background: #dadada; }

html, body {
  padding: 0;
  margin: 0; }

h1, h2, h3, h4, h5, h6, p, li, ul, span, div, a {
  margin: 0;
  padding: 0;
  font-family: "Gridnik";
  font-weight: 400;
  color: inherit;
  -webkit-font-smoothing: antialiased;
  text-rendering: geometricPrecision;
  -moz-osx-font-smoothing: grayscale; }

sup {
  line-height: 0; }

td {
  line-height: 1.5; }

.italic,
.italic * {
  font-style: italic; }

.underline,
.underline * {
  text-decoration: underline; }

.bold,
.bold * {
  font-weight: 500; }

ul, ul li {
  list-style: none; }

a {
  text-decoration: none; }

.qp-link {
  cursor: pointer; }
  .qp-link span {
    text-decoration: underline; }

p a {
  text-decoration: underline; }

p, p span, p a {
  font-family: "LibreBaskerville";
  font-size: 20px;
  line-height: 2;
  color: #303030;
  text-rendering: geometricPrecision; }

h1, h2, h3, h4, h5, h6, p, div {
  color: #303030; }

span {
  color: inherit; }

blockquote {
  margin: 30px 70px; }

img, video {
  max-width: 100%; }

.sub-text {
  font-size: 14px;
  line-height: 2; }

* {
  box-sizing: border-box; }

audio, video {
  outline: none; }

.group::after {
  content: "";
  display: block;
  clear: both; }

.cd ::-webkit-scrollbar, .cd * ::-webkit-scrollbar {
  display: none; }

.wrapper {
  position: relative; }

.flex-wrapper {
  display: flex; }

.page {
  margin-bottom: 47px; }

.key {
  position: fixed;
  top: 12px;
  left: 0px;
  width: 80px;
  z-index: 100;
  mix-blend-mode: multiply; }

.article-link {
  color: red;
  cursor: pointer; }

.video__play-button {
  position: absolute;
  top: 50%;
  cursor: pointer;
  z-index: 1;
  line-height: 1;
  left: 50%;
  transform: translate(-50%, -50%); }

.table {
  display: flex;
  font-size: 13px; }

.table-half {
  flex: 1; }

.tr {
  margin: 35px 0;
  line-height: 1.5em !important; }

.rsp-img-wrap {
  position: relative;
  line-height: 0; }
  .rsp-img-wrap .rsp-img-wrap__overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }

.full-poem {
  line-height: 1.4; }

.full-poem-text, .full-poem-author, .full-poem.title {
  margin-bottom: 20px; }

.full-poem-author {
  line-height: 1;
  font-size: 18px !important; }

em {
  line-height: 1.5 !important; }

@keyframes slide-up-in {
  from {
    transform: translate3d(0, 100%, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

#why-call-it-the-anthropocene-see-also {
  display: inline; }

.landscape__posX, .landscape__posY {
  background: red;
  position: absolute;
  top: 0;
  left: 0; }

.landscape__posX {
  width: 100%;
  height: 1px; }

.landscape__posY {
  position: fixed;
  width: 1px;
  height: 100%; }

.positioning-app__coords {
  position: fixed;
  top: 20px;
  left: 20px;
  color: red; }

.positioning-app__landscape-image {
  max-width: initial;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; }

.positioning-app__saved-tooltip {
  background: #00E83B;
  border-radius: 20px;
  padding: 5px 20px;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%); }

.content-modal {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 47px;
  background: rgba(32, 32, 32, 0.9);
  display: flex; }

.content-modal__copy {
  flex-basis: 500px;
  padding: 20px;
  height: 100%;
  overflow: scroll; }
  .content-modal__copy p {
    color: white;
    margin: 20px 0; }

.content-modal__image {
  flex: 1;
  position: relative; }

.content-modal__image img {
  max-width: 80%;
  max-height: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.poster-lightbox {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 47px;
  background: rgba(255, 255, 255, 0.9);
  height: calc(100vh - 47px);
  display: flex;
  overflow: scroll;
  padding: 20px 0;
  z-index: 80; }

.poster-lightbox-content {
  height: calc(100vh - 110px);
  width: 100%;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .poster-lightbox-content .rsp-img-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .poster-lightbox-content .rsp-img-wrap img {
      max-width: 90%;
      max-height: 90%;
      position: absolute;
      top: 0; }
  .poster-lightbox-content p, .poster-lightbox-content span,
  .poster-lightbox-content p span, .poster-lightbox-content p a {
    display: none;
    color: #fff !important; }

.close-button {
  position: absolute;
  top: 10px;
  right: 0;
  left: 0;
  margin: 0 auto;
  text-align: center;
  font-size: 1.5em;
  color: #000 !important;
  z-index: 999;
  cursor: pointer; }

.tr-el {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  z-index: 100;
  opacity: 0;
  pointer-events: none; }

.tr-el--st-1 {
  transition: opacity .25s ease-in-out;
  opacity: 1; }

.tr-el--st-2 {
  transition: opacity .8s ease-in-out;
  opacity: 0; }

.expl-el {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  z-index: 2;
  pointer-events: none;
  animation: flash .5s linear infinite; }

@keyframes flash {
  25% {
    background: #AD0306; }
  50% {
    background: #00611B; }
  75% {
    background: yellow; }
  100% {
    background: red; } }

.cd {
  position: fixed;
  bottom: 0;
  background: #fafafa;
  border-top: 1px solid #999;
  z-index: 10;
  width: 100%;
  height: 100vh;
  transition: transform .3s ease-out;
  transition-delay: .05s;
  z-index: 70; }

.cd__inner {
  position: relative;
  height: 100%; }

.cd__slide-content {
  width: 200%;
  display: flex;
  transition: transform .5s ease-in-out;
  transition-delay: .3s; }
  .cd__slide-content p {
    font-size: 18px !important;
    line-height: 1.75em !important;
    font-family: "Gridnik" !important;
    margin: 12px 0 !important;
    padding-right: 15px;
    width: 100%; }
  .cd__slide-content .poster__subtitle, .cd__slide-content .poster__author, .cd__slide-content p, .cd__slide-content p span {
    font-size: 18px !important;
    font-family: "Gridnik" !important; }
  .cd__slide-content .poster__subtitle, .cd__slide-content .poster__author {
    font-size: 26px !important; }
  .cd__slide-content > div {
    width: 50%;
    position: relative; }

.cd__slide-content--slide-left {
  transform: translate3d(-50%, 0, 0); }

.cd__content-wrap {
  background: #fafafa;
  z-index: 20;
  padding-left: 500px;
  padding-right: 20px;
  padding-bottom: 80px;
  padding-top: 50px;
  scroll-behavior: smooth;
  overflow-y: scroll; }

.cd__article-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background: #fafafa;
  border-top: 1px solid #999;
  transform: translate3d(0, 100%, 0);
  transition: transform .5s ease-in-out;
  transition-delay: .05s;
  z-index: 100; }

.cd__article-modal__inner,
.cd__preview {
  display: flex;
  padding-bottom: 60px;
  font-family: "Gridnik" !important;
  height: calc(100vh - 49px) !important; }
  .cd__article-modal__inner .qp-link,
  .cd__preview .qp-link {
    text-decoration: underline;
    color: inherit; }
  .cd__article-modal__inner .contributor-bio,
  .cd__preview .contributor-bio {
    margin-bottom: 30px; }
  .cd__article-modal__inner .cd__menu__inner .qp-link,
  .cd__preview .cd__menu__inner .qp-link {
    text-decoration: none;
    text-indent: -1.125em;
    margin-left: 1.125em;
    margin-top: 0;
    margin-bottom: 8px;
    line-height: 1.125em !important;
    display: inline-block;
    max-width: 260px;
    font-size: 1em;
    width: 100%; }
  .cd__article-modal__inner .cd__menu__inner .qp-link h5,
  .cd__preview .cd__menu__inner .qp-link h5 {
    font-size: 1em;
    line-height: 1.35em !important; }
  .cd__article-modal__inner center,
  .cd__preview center {
    display: block;
    width: 100%;
    text-align: center; }
  .cd__article-modal__inner li, .cd__article-modal__inner p, .cd__article-modal__inner span,
  .cd__preview li,
  .cd__preview p,
  .cd__preview span {
    line-height: 1.5; }
  .cd__article-modal__inner .poster__body__block__caption span,
  .cd__preview .poster__body__block__caption span {
    font-size: 12px !important !important;
    line-height: 1.2 !important !important; }
  .cd__article-modal__inner .essay__body__text,
  .cd__preview .essay__body__text {
    margin-bottom: 36px; }
  .cd__article-modal__inner .poster__subtitle, .cd__article-modal__inner .essay__body__text, .cd__article-modal__inner .poster__author, .cd__article-modal__inner p, .cd__article-modal__inner p span, .cd__article-modal__inner p span a,
  .cd__preview .poster__subtitle,
  .cd__preview .essay__body__text,
  .cd__preview .poster__author,
  .cd__preview p,
  .cd__preview p span,
  .cd__preview p span a {
    font-size: 18px !important;
    font-family: "Gridnik" !important; }
  .cd__article-modal__inner .poster__subtitle, .cd__article-modal__inner .poster__author,
  .cd__preview .poster__subtitle,
  .cd__preview .poster__author {
    font-size: 26px !important; }
  .cd__article-modal__inner p span, .cd__article-modal__inner p a, .cd__article-modal__inner p sup,
  .cd__preview p span,
  .cd__preview p a,
  .cd__preview p sup {
    line-height: 0 !important; }
  .cd__article-modal__inner p span blockquote,
  .cd__preview p span blockquote {
    line-height: 1.75em !important; }
  .cd__article-modal__inner sup span,
  .cd__preview sup span {
    font-size: 16px !important; }
  .cd__article-modal__inner a,
  .cd__preview a {
    display: inline; }
  .cd__article-modal__inner .cd__body__section ul,
  .cd__article-modal__inner .cd__body__section li,
  .cd__article-modal__inner .cd__body__section p,
  .cd__article-modal__inner .cd__body__section h3,
  .cd__article-modal__inner .cd__body__section h4,
  .cd__article-modal__inner .cd__body__section span,
  .cd__article-modal__inner .indeces__body_section,
  .cd__preview .cd__body__section ul,
  .cd__preview .cd__body__section li,
  .cd__preview .cd__body__section p,
  .cd__preview .cd__body__section h3,
  .cd__preview .cd__body__section h4,
  .cd__preview .cd__body__section span,
  .cd__preview .indeces__body_section {
    font-family: "circe-slab-b" !important;
    font-size: 19px !important;
    margin: 12px 0 !important;
    width: 100%; }
    .cd__article-modal__inner .cd__body__section ul .poster__body__reference,
    .cd__article-modal__inner .cd__body__section li .poster__body__reference,
    .cd__article-modal__inner .cd__body__section p .poster__body__reference,
    .cd__article-modal__inner .cd__body__section h3 .poster__body__reference,
    .cd__article-modal__inner .cd__body__section h4 .poster__body__reference,
    .cd__article-modal__inner .cd__body__section span .poster__body__reference,
    .cd__article-modal__inner .indeces__body_section .poster__body__reference,
    .cd__preview .cd__body__section ul .poster__body__reference,
    .cd__preview .cd__body__section li .poster__body__reference,
    .cd__preview .cd__body__section p .poster__body__reference,
    .cd__preview .cd__body__section h3 .poster__body__reference,
    .cd__preview .cd__body__section h4 .poster__body__reference,
    .cd__preview .cd__body__section span .poster__body__reference,
    .cd__preview .indeces__body_section .poster__body__reference {
      color: #757575;
      cursor: pointer; }
      .cd__article-modal__inner .cd__body__section ul .poster__body__reference span,
      .cd__article-modal__inner .cd__body__section li .poster__body__reference span,
      .cd__article-modal__inner .cd__body__section p .poster__body__reference span,
      .cd__article-modal__inner .cd__body__section h3 .poster__body__reference span,
      .cd__article-modal__inner .cd__body__section h4 .poster__body__reference span,
      .cd__article-modal__inner .cd__body__section span .poster__body__reference span,
      .cd__article-modal__inner .indeces__body_section .poster__body__reference span,
      .cd__preview .cd__body__section ul .poster__body__reference span,
      .cd__preview .cd__body__section li .poster__body__reference span,
      .cd__preview .cd__body__section p .poster__body__reference span,
      .cd__preview .cd__body__section h3 .poster__body__reference span,
      .cd__preview .cd__body__section h4 .poster__body__reference span,
      .cd__preview .cd__body__section span .poster__body__reference span,
      .cd__preview .indeces__body_section .poster__body__reference span {
        color: #757575;
        font-size: 13px !important; }
    .cd__article-modal__inner .cd__body__section ul .full-poem-author,
    .cd__article-modal__inner .cd__body__section li .full-poem-author,
    .cd__article-modal__inner .cd__body__section p .full-poem-author,
    .cd__article-modal__inner .cd__body__section h3 .full-poem-author,
    .cd__article-modal__inner .cd__body__section h4 .full-poem-author,
    .cd__article-modal__inner .cd__body__section span .full-poem-author,
    .cd__article-modal__inner .indeces__body_section .full-poem-author,
    .cd__preview .cd__body__section ul .full-poem-author,
    .cd__preview .cd__body__section li .full-poem-author,
    .cd__preview .cd__body__section p .full-poem-author,
    .cd__preview .cd__body__section h3 .full-poem-author,
    .cd__preview .cd__body__section h4 .full-poem-author,
    .cd__preview .cd__body__section span .full-poem-author,
    .cd__preview .indeces__body_section .full-poem-author {
      font-size: 1.1em;
      margin-bottom: 22px; }
    .cd__article-modal__inner .cd__body__section ul .full-poem-title,
    .cd__article-modal__inner .cd__body__section li .full-poem-title,
    .cd__article-modal__inner .cd__body__section p .full-poem-title,
    .cd__article-modal__inner .cd__body__section h3 .full-poem-title,
    .cd__article-modal__inner .cd__body__section h4 .full-poem-title,
    .cd__article-modal__inner .cd__body__section span .full-poem-title,
    .cd__article-modal__inner .indeces__body_section .full-poem-title,
    .cd__preview .cd__body__section ul .full-poem-title,
    .cd__preview .cd__body__section li .full-poem-title,
    .cd__preview .cd__body__section p .full-poem-title,
    .cd__preview .cd__body__section h3 .full-poem-title,
    .cd__preview .cd__body__section h4 .full-poem-title,
    .cd__preview .cd__body__section span .full-poem-title,
    .cd__preview .indeces__body_section .full-poem-title {
      font-size: 1.3em;
      margin-bottom: 12px; }
    .cd__article-modal__inner .cd__body__section ul .full-poem-text,
    .cd__article-modal__inner .cd__body__section li .full-poem-text,
    .cd__article-modal__inner .cd__body__section p .full-poem-text,
    .cd__article-modal__inner .cd__body__section h3 .full-poem-text,
    .cd__article-modal__inner .cd__body__section h4 .full-poem-text,
    .cd__article-modal__inner .cd__body__section span .full-poem-text,
    .cd__article-modal__inner .indeces__body_section .full-poem-text,
    .cd__preview .cd__body__section ul .full-poem-text,
    .cd__preview .cd__body__section li .full-poem-text,
    .cd__preview .cd__body__section p .full-poem-text,
    .cd__preview .cd__body__section h3 .full-poem-text,
    .cd__preview .cd__body__section h4 .full-poem-text,
    .cd__preview .cd__body__section span .full-poem-text,
    .cd__preview .indeces__body_section .full-poem-text {
      font-size: .9675em; }
  .cd__article-modal__inner ul li::marker,
  .cd__preview ul li::marker {
    content: '\2014'; }
  .cd__article-modal__inner .cd__body__section h3,
  .cd__preview .cd__body__section h3 {
    font-size: 19px !important; }
  .cd__article-modal__inner .cd__body__section h4,
  .cd__preview .cd__body__section h4 {
    font-size: 17px !important; }
  .cd__article-modal__inner .cd__body__section li,
  .cd__article-modal__inner .cd__body__section p,
  .cd__article-modal__inner .indeces__body_section,
  .cd__preview .cd__body__section li,
  .cd__preview .cd__body__section p,
  .cd__preview .indeces__body_section {
    padding-right: 15px; }
  .cd__article-modal__inner .cd__content-wrap,
  .cd__preview .cd__content-wrap {
    flex: 1; }
  .cd__article-modal__inner .cd__body__section.is-blockquote,
  .cd__preview .cd__body__section.is-blockquote {
    margin: 30px 50px; }
  .cd__article-modal__inner .cd__body__section.is-blockquote blockquote,
  .cd__preview .cd__body__section.is-blockquote blockquote {
    display: inline;
    margin: 0; }

.cd__article-modal--active {
  transform: translateY(0); }

.cd__article-modal__close {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: #fafafa;
  border-radius: 3px;
  text-decoration: none !important;
  padding: 6px 12px; }

.cd--preview {
  transform: translateY(0px); }

.cd__header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  height: 47px; }

.cd__header__content-name, .cd__header__content-type {
  color: #666; }

.cd__header__content-name {
  font-size: 17px;
  text-transform: uppercase; }

.cd__header__content-type {
  font-size: 17px; }

.cd__hamburger {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 19px;
  width: 36px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-direction: column; }
  .cd__hamburger > div {
    background: #666;
    width: 100%;
    height: 1px; }

.cd__menu {
  position: relative;
  z-index: 21;
  margin-top: 30px; }

.cd__menu__inner {
  width: 275px;
  border-top: 1px solid black;
  margin: 0 auto;
  padding: 15px 0 100px 0;
  position: fixed;
  left: 115px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 70vh; }
  .cd__menu__inner h3 {
    display: block;
    padding: 0;
    margin-bottom: 10px; }
  .cd__menu__inner .drawer-menu-section {
    border-bottom: 1px solid #999;
    margin: 0 0 8px 0;
    padding: 5px 0 8px 0; }

.cd__menu__link {
  font-size: 14px;
  margin: 4px 0;
  text-indent: -1.5em;
  margin-left: 1.5em; }

.cd__preview {
  display: flex;
  padding: 0;
  padding-top: 0;
  padding-bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden; }

.cd__content-wrap.landing,
.cd__content-wrap.field-report {
  font-size: 18px !important;
  line-height: 1.75em !important;
  font-family: "Gridnik" !important;
  padding-right: 15px;
  width: 100%; }

.cd__preview__summary {
  flex: 1; }
  .cd__preview__summary p {
    font-size: 18px !important;
    line-height: 1.75em !important;
    font-family: "Gridnik" !important;
    margin: 12px 0 !important;
    padding-right: 15px;
    width: 100%; }

.cd__body__section {
  display: flex; }
  .cd__body__section p {
    margin: 18px 0; }

.indeces__body__section {
  margin: 18px 12px 18px 0;
  padding-right: 15px; }
  .indeces__body__section span, .indeces__body__section a {
    font-size: 18px !important;
    line-height: 1.75em !important;
    font-family: "Gridnik" !important;
    width: 100%; }
  .indeces__body__section a {
    text-decoration: underline; }

.cd__reading-room-btn {
  position: absolute;
  top: 12px;
  right: 20px;
  z-index: 100;
  font-size: 14px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: #fafafa;
  border-radius: 3px;
  padding: 6px 12px; }
  .cd__reading-room-btn img {
    width: 7px; }

.cd__fq-carousel-section {
  margin: 60px 0;
  width: 560px;
  margin: 60px auto 40px;
  text-align: center; }

.cd__stanford-footer {
  background: black;
  display: flex;
  justify-content: space-between;
  padding: 9px 12px;
  align-items: center; }
  .cd__stanford-footer .sup-logo {
    height: 22px;
    margin-left: 8px; }
  .cd__stanford-footer .r {
    display: flex;
    align-items: center; }
  .cd__stanford-footer * {
    font-size: 11px;
    color: white; }

.cd table {
  margin-top: -20px;
  position: relative;
  background: #fafafa; }

.arrow {
  width: 35px;
  cursor: pointer; }

.slideshow {
  position: relative;
  text-align: center; }
  .slideshow img {
    max-height: 100%; }

.slideshow__increment {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%); }

.slideshow__decrement {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%); }

.critter__carousel-wrapper {
  width: 500px;
  margin: 0 30px; }

.critter__carousel {
  width: 100%;
  position: relative;
  overflow: hidden; }

.critter__carousel--hovered {
  overflow: initial; }

.critter__carousel__inner {
  display: flex;
  white-space: nowrap;
  transition: transform .15s ease-out; }

.critter__carousel__image-wrap {
  text-align: center;
  width: 100px;
  padding: 0;
  white-space: normal;
  opacity: .2; }
  .critter__carousel__image-wrap.visited {
    opacity: 1; }

.critter__carousel__image-wrap--hidden {
  opacity: 0; }

.critter__carousel__image {
  width: 100%; }

.critter__carousel__image--disabled {
  opacity: .2; }

.critter__carousel .critter__carousel__critter-name {
  font-weight: 500;
  font-size: 12px; }
  .critter__carousel .critter__carousel__critter-name span {
    line-height: 1.2 !important; }

.critter__carousel__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  z-index: 10;
  transition: opacity .2s ease-out; }
  .critter__carousel__arrow.hide {
    opacity: 0; }

.infrastructure__videos:hover .critter__carousel__arrow {
  opacity: 1; }

.critter__carousel__arrow--left {
  left: -30px; }

.critter__carousel__arrow--right {
  right: -30px; }

.reading-room {
  padding: 0 20px;
  overflow: scroll; }

.reading-room__wrapper {
  display: flex; }

.reading-room-title {
  margin-bottom: 50px; }

.reading-room__column {
  padding: 0 10px;
  flex: 1; }

.reading-room__section {
  margin: 0 0 50px 0;
  width: 100%; }
  .reading-room__section .qp-link {
    display: block;
    text-indent: -1.125em;
    margin-left: 1.125em;
    margin-bottom: 4px; }

.reading-room__section__header {
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  margin-bottom: 12px;
  padding: 3px 0;
  text-align: center; }

.g-l {
  position: relative; }
  .g-l span {
    text-decoration: underline;
    cursor: pointer; }

.g-l__modal {
  position: absolute;
  z-index: 10;
  padding: 20px;
  border: 1px solid black;
  top: 0;
  left: 50%;
  background: #fafafa;
  border-radius: 4px;
  transform: translate(-50%, -50%); }

.g-l__link {
  font-size: 14px;
  margin: 5px 0; }

.landing__title-card {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  width: 800px; }

.landing__title-card__critter {
  font-size: 72px;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize; }

.landing__title-card__landscapes {
  margin-top: 20px;
  display: flex;
  justify-content: space-between; }
  .landing__title-card__landscapes h5 {
    font-size: 18px;
    font-weight: 500; }

.landing__critter-img-wrap {
  display: inline-block;
  background: gray;
  border: 1px solid black;
  position: absolute; }

.landing__critter-img-link {
  max-width: 300px; }

.landing__title-card {
  pointer-events: none; }

.landing__title-card__landscape {
  color: #999;
  opacity: .6; }

.landing__title-card__landscape--active {
  color: black;
  opacity: 1; }

.critter-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.critter-canvas--cl {
  cursor: pointer; }

.landscape__explosion {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  transition: opacity 1.5s ease; }
  .landscape__explosion.fade {
    opacity: 0;
    pointer-events: none; }
  .landscape__explosion video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover; }

.landscape__poi-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.6); }

.landscape__poi-overlay--local {
  width: 300px;
  background: #fff;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 3px;
  height: auto;
  width: 250px; }
  .landscape__poi-overlay--local .landscape__text {
    display: block;
    position: static;
    transform: none; }
  .landscape__poi-overlay--local .landscape__poi__image {
    width: 150px;
    height: 150px; }
  .landscape__poi-overlay--local .landscape__poi__name {
    font-size: 16px;
    width: 100%; }

.landscape__poi__image {
  width: 300px; }

.landscape__poi__name {
  width: 400px;
  text-align: center;
  margin: 0 auto; }

.landscape__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center; }

.landscape__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  contain: strict;
  overflow: hidden; }

.landscape__board {
  width: 2600px; }

.landscape__canvas {
  background: #D8D8D9;
  cursor: grab; }
  .landscape__canvas.grabbed {
    cursor: grabbing; }

.landscape__canvas--showCursor {
  cursor: pointer; }

.landscape__board__piece {
  display: inline-block;
  padding: 20px;
  justify-content: center;
  align-items: center;
  transition: background 1s ease-in-out;
  cursor: move;
  /* fallback if grab cursor is unsupported */
  transition-delay: .3s; }

.critter-infrastructure-link {
  height: 26px;
  width: 26px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 0;
  left: 0;
  border-radius: 50%;
  border: 2px solid white;
  background: #777777; }
  .critter-infrastructure-link > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 50%;
    height: 4px;
    width: 4px; }

.infrastructure {
  padding: 0 90px;
  padding-top: 40px; }

.infrastructure__active-poster-links {
  position: fixed;
  display: flex;
  top: 30px;
  z-index: 10;
  right: 100px; }

.infrastructure__continue, .infrastructure__see-also {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: #fafafa;
  border-radius: 3px;
  padding: 8px 12px;
  display: inline-block;
  font-weight: 500;
  height: 32px; }
  .infrastructure__continue > h5, .infrastructure__see-also > h5 {
    display: flex;
    align-items: center; }
    .infrastructure__continue > h5 > *, .infrastructure__see-also > h5 > * {
      margin: 0 2px; }
  .infrastructure__continue img, .infrastructure__see-also img {
    height: 10px; }

.infrastructure__continue-dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 6px !important;
  display: inline-block;
  background: #e53134;
  animation: blink 1000ms infinite; }

@keyframes blink {
  from {
    opacity: 1.0; }
  50% {
    opacity: 0.5; }
  to {
    opacity: 1.0; } }

.infrastructure__see-also {
  margin-right: 20px; }

.infrastructure__info-section {
  margin: 40px 0;
  display: flex; }
  .infrastructure__info-section > div {
    flex: 1; }

.infrastructure__poi-info {
  position: relative; }

.infrastructure__poi-image {
  width: 100%; }

.infrastructure__poi-critter {
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  background: rgba(240, 240, 240, 0.96);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 30px;
  padding: 0 20px 0 8px; }
  .infrastructure__poi-critter img {
    width: 50px; }

.infrastructure__general-info {
  padding-right: 40px; }

.infrastructure__name {
  font-size: 40px;
  margin-bottom: 20px;
  text-transform: uppercase; }

.infrastructure__short-description {
  font-size: 16px;
  line-height: 1.5; }

.infrastructure__videos {
  margin-bottom: 100px; }
  .infrastructure__videos video {
    width: 100%; }
  .infrastructure__videos .main-video-section {
    margin-bottom: 40px; }
  .infrastructure__videos .video__caption {
    font-size: 12px; }
  .infrastructure__videos .video__carousel__inner {
    display: flex;
    align-items: center;
    line-height: 0; }
  .infrastructure__videos .video__carousel__image-wrap {
    display: inline-block;
    line-height: 0;
    width: 25%; }
  .infrastructure__videos .video__carousel__image-wrap {
    cursor: pointer; }
  .infrastructure__videos .other-video-section {
    display: flex; }
    .infrastructure__videos .other-video-section .video-description-section {
      padding-right: 40px; }
    .infrastructure__videos .other-video-section .video-description {
      font-size: 16px; }
    .infrastructure__videos .other-video-section > div {
      flex: 1; }

.infrastructure__poem {
  width: 600px;
  margin: 120px auto 180px;
  display: block; }

.infrastructure__other-critters {
  width: 560px;
  margin: 60px auto 120px;
  text-align: center; }
  .infrastructure__other-critters h3 {
    margin-bottom: 8px; }

.page--poster {
  background: #fafafa;
  width: 100%; }

.poster__body {
  margin: 0 auto; }

.poster__header {
  position: relative;
  line-height: 0;
  z-index: 11; }

.poster__header__sheet {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.6); }

.poster__header__credits {
  line-height: 1.3;
  top: 100%;
  padding: 0 60px !important;
  width: 100%; }

.poster__header__credits__credit, .poster__header__credits__caption, .poster__body__block__caption {
  display: inline; }
  .poster__header__credits__credit span, .poster__header__credits__credit a, .poster__header__credits__caption span, .poster__header__credits__caption a, .poster__body__block__caption span, .poster__body__block__caption a {
    display: inline;
    font-size: 12px !important;
    line-height: 1.2 !important;
    font-family: "LibreBaskerville" !important; }

.poster__header__credits__credit span, .poster__header__credits__credit a {
  font-size: 11px !important; }

.poster__media-wrapper--no-scale .poster__header__credits {
  padding-left: 0; }

.poster__header__credits__credit {
  font-weight: bold;
  margin: 5px 0; }

.poster__title {
  position: absolute;
  z-index: 10;
  top: 100px;
  left: 40px;
  max-width: 75%;
  pointer-events: none;
  color: #fff;
  text-transform: uppercase;
  font-size: 72px;
  line-height: 1.3; }

.poster-footer-content {
  padding-left: 500px;
  max-width: 1500px;
  padding-right: 50px;
  margin: 18px auto; }

.poster__subheader {
  display: flex;
  align-items: center;
  max-width: 1500px;
  padding: 0 50px;
  margin: 100px auto 0 auto; }
  .poster__subheader .image-and-see-more {
    flex-basis: 450px;
    position: relative; }
    .poster__subheader .image-and-see-more a {
      text-decoration: underline; }
    .poster__subheader .image-and-see-more span {
      display: inline-block;
      max-width: 70%; }
    .poster__subheader .image-and-see-more > span {
      margin-bottom: 12px; }
  .poster__subheader .titles {
    max-width: 1500px;
    flex: 1 1; }

.poster__subtitle.poster, .poster__author.poster {
  font-family: "LibreBaskerville" !important;
  position: relative;
  z-index: 1;
  font-size: 20px; }
  .poster__subtitle.poster span, .poster__subtitle.poster a, .poster__author.poster span, .poster__author.poster a {
    font-family: "LibreBaskerville" !important; }

.poster__subheader__critter {
  width: 200px;
  margin-left: 200px; }

.poster__subtitle {
  font-size: 32px !important; }

.poster__subtitle {
  display: inline;
  line-height: 1.5; }

.poster__author {
  margin-top: 28px;
  line-height: 2; }

.poster__discipline {
  font-size: 14px; }
  .poster__discipline span {
    font-size: 14px; }

.poster__body__fq, .poster__body__inline-image-wrap, .poster__body__block__image-wrap, .poster__header__credits {
  padding: 0 60px 0 80px;
  flex-basis: 500px;
  position: relative;
  z-index: 1; }
  .poster__body__fq .reference-link, .poster__body__inline-image-wrap .reference-link, .poster__body__block__image-wrap .reference-link, .poster__header__credits .reference-link {
    text-decoration: underline;
    font-family: "LibreBaskerville" !important;
    cursor: pointer;
    position: relative;
    font-size: 12px !important;
    line-height: 1.2 !important;
    padding-right: 5px;
    padding-left: 5px; }
    .poster__body__fq .reference-link.more, .poster__body__inline-image-wrap .reference-link.more, .poster__body__block__image-wrap .reference-link.more, .poster__header__credits .reference-link.more {
      padding-right: 2px;
      padding-left: 0px; }
  .poster__body__fq .poster__body__block__caption *, .poster__body__inline-image-wrap .poster__body__block__caption *, .poster__body__block__image-wrap .poster__body__block__caption *, .poster__header__credits .poster__body__block__caption * {
    font-size: 12px !important;
    line-height: 1.2 !important; }
  .poster__body__fq .hover-caption, .poster__body__inline-image-wrap .hover-caption, .poster__body__block__image-wrap .hover-caption, .poster__header__credits .hover-caption {
    background: #fafafa;
    border-radius: 3px;
    display: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 8px 12px;
    position: absolute;
    top: 120%;
    left: 40%;
    width: 100%;
    min-width: 300px;
    z-index: 9;
    cursor: default;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    line-height: 1.5; }
    .poster__body__fq .hover-caption *, .poster__body__inline-image-wrap .hover-caption *, .poster__body__block__image-wrap .hover-caption *, .poster__header__credits .hover-caption * {
      font-family: "LibreBaskerville" !important;
      font-size: 12px !important;
      line-height: 1.6; }

.poster__body__fq .reference-link {
  font-family: "Gridnik" !important;
  font-size: 14px !important; }

.pinned--r-l-1 .r-l-1 .hover-caption {
  display: block !important; }

.pinned--r-l-2 .r-l-2 .hover-caption {
  display: block !important; }

.pinned--r-l-3 .r-l-3 .hover-caption {
  display: block !important; }

.poster__body__fq .poster__body__block__image-wrap {
  padding: 0;
  margin-top: 20px; }

.poster__body__block__image-wrap .hover-caption,
.poster__header__credits .hover-caption {
  left: -140px; }

.poster__body__fq {
  z-index: 20 !important; }
  .poster__body__fq .hover-caption {
    font-family: "Gridnik" !important; }

.poster__header__credits {
  padding: 0; }

.poster__body__reference {
  color: #757575;
  cursor: pointer; }
  .poster__body__reference span {
    color: #757575;
    font-size: 10px; }

.toggle {
  cursor: pointer; }
  .toggle.see-more {
    text-decoration: underline; }

.poster__body__inline-image-wrap > span.image-wrapper, .poster__body__inline-image-wrap .toggle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 60px 0 80px; }

.fq, .fq span {
  transition: color .2s ease-in-out; }

.fq-section {
  text-align: left;
  position: absolute;
  padding: 0 40px 0 80px;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50; }
  .fq-section h3 {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #fafafa;
    height: 14px;
    line-height: 0.1em;
    margin: 10px 0 20px;
    text-decoration: none !important; }
  .fq-section h3 span {
    background: #fafafa;
    padding: 0 20px; }
  .fq-section * {
    font-family: "Gridnik";
    line-height: 1.8 !important; }

.fq-section__name {
  color: #b5b4a1;
  cursor: pointer;
  text-decoration: underline; }

.fq--active {
  color: #b5b4a1; }

.poster__body__fq p, .poster__body__fq span {
  font-size: 14px; }

.poster__body__fq.block {
  padding: 20px 0 20px 500px;
  max-width: 1500px;
  padding-right: 50px;
  margin: 18px auto;
  font-size: 14px; }
  .poster__body__fq.block .fq-section {
    padding: 0;
    position: relative; }
    .poster__body__fq.block .fq-section .poster__body__text {
      padding: 0; }

.poster__body__fq .poster__body__section .poster__body__text {
  line-height: normal !important;
  margin: 12px 0 0 0 !important;
  padding: 0; }
  .poster__body__fq .poster__body__section .poster__body__text:first-of-type {
    display: inline; }

.poster__body__fq .poster__body__section:first-of-type {
  display: inline; }

.poster__body__fq * {
  font-family: "Gridnik" !important; }

.poster__body__fq .sidebar-poem,
.poster__body__fq .sidebar-poem-text,
.poster__body__fq .sidebar-poem-title,
.poster__body__fq .sidebar-poem-author,
.poster__body__fq .full-poem,
.poster__body__fq .full-poem-text,
.poster__body__fq .full-poem-title,
.poster__body__fq .full-poem-author {
  font-family: adobe-garamond-pro !important; }

.poster__body__fq a {
  text-decoration: underline; }

.fq__other-critters {
  margin: 20px auto;
  width: 300px;
  position: relative; }

.fq__line {
  height: 2px;
  background: #b5b4a1; }

.fq__standalone-container {
  display: flex; }

.fq__standalone-analysis-text {
  flex-basis: 440px;
  padding-right: 60px; }
  .fq__standalone-analysis-text p {
    font-size: 14px; }

.fq__standalone-body-text {
  flex: 1; }
  .fq__standalone-body-text div, .fq__standalone-body-text p, .fq__standalone-body-text span {
    color: inherit;
    font-size: 20px; }
  .fq__standalone-body-text .poster__body__section p {
    padding-left: 0; }
    .fq__standalone-body-text .poster__body__section p span {
      font-size: 20px; }

.fq__standalone-analysis-text p, .fq__standalone-analysis-text span {
  font-size: 14px; }

.poster__body__fq--type-2 .fq__line {
  background: red; }

.poster__body__fq--standalone .fq__line {
  background: blue; }

.poster__body__fq--standalone {
  padding-right: 0;
  padding-left: 0; }

.poster__body__text {
  flex: 1;
  line-height: 2;
  margin: 18px 0;
  font-size: 16px; }
  .poster__body__text a {
    font-family: "LibreBaskerville"; }
    .poster__body__text a span {
      text-decoration: underline; }
  .poster__body__text h3, .poster__body__text h4 {
    font-family: "LibreBaskerville" !important;
    display: inline; }
  .poster__body__text h4 {
    font-size: 17px !important; }

.poster__body__block__image-wrap,
.poster__body__block__image-wrap--L,
.poster__body__block__image-wrap--ML,
.poster__body__block__image-wrap--M,
.poster__body__block__image-wrap--MS,
.poster__body__block__image-wrap--S,
.poster__body__block__image-wrap--XS {
  width: 100%;
  padding-left: 500px;
  margin: 0 auto 22px auto;
  max-width: 1500px; }
  .poster__body__block__image-wrap video,
  .poster__body__block__image-wrap--L video,
  .poster__body__block__image-wrap--ML video,
  .poster__body__block__image-wrap--M video,
  .poster__body__block__image-wrap--MS video,
  .poster__body__block__image-wrap--S video,
  .poster__body__block__image-wrap--XS video {
    width: 100%; }
  .poster__body__block__image-wrap > span.image-wrapper, .poster__body__block__image-wrap .toggle,
  .poster__body__block__image-wrap--L > span.image-wrapper,
  .poster__body__block__image-wrap--L .toggle,
  .poster__body__block__image-wrap--ML > span.image-wrapper,
  .poster__body__block__image-wrap--ML .toggle,
  .poster__body__block__image-wrap--M > span.image-wrapper,
  .poster__body__block__image-wrap--M .toggle,
  .poster__body__block__image-wrap--MS > span.image-wrapper,
  .poster__body__block__image-wrap--MS .toggle,
  .poster__body__block__image-wrap--S > span.image-wrapper,
  .poster__body__block__image-wrap--S .toggle,
  .poster__body__block__image-wrap--XS > span.image-wrapper,
  .poster__body__block__image-wrap--XS .toggle {
    display: block;
    margin: 0 auto; }
    .poster__body__block__image-wrap > span.image-wrapper img, .poster__body__block__image-wrap .toggle img,
    .poster__body__block__image-wrap--L > span.image-wrapper img,
    .poster__body__block__image-wrap--L .toggle img,
    .poster__body__block__image-wrap--ML > span.image-wrapper img,
    .poster__body__block__image-wrap--ML .toggle img,
    .poster__body__block__image-wrap--M > span.image-wrapper img,
    .poster__body__block__image-wrap--M .toggle img,
    .poster__body__block__image-wrap--MS > span.image-wrapper img,
    .poster__body__block__image-wrap--MS .toggle img,
    .poster__body__block__image-wrap--S > span.image-wrapper img,
    .poster__body__block__image-wrap--S .toggle img,
    .poster__body__block__image-wrap--XS > span.image-wrapper img,
    .poster__body__block__image-wrap--XS .toggle img {
      width: 100%; }

.essay__image.poster__body__block__image-wrap,
.essay__image.poster__body__block__image-wrap--L,
.essay__image.poster__body__block__image-wrap--ML,
.essay__image.poster__body__block__image-wrap--M,
.essay__image.poster__body__block__image-wrap--MS,
.essay__image.poster__body__block__image-wrap--S,
.essay__image.poster__body__block__image-wrap--XS {
  width: 100%;
  padding-left: 0;
  margin: 0 auto 22px auto;
  max-width: 1500px; }
  .essay__image.poster__body__block__image-wrap audio,
  .essay__image.poster__body__block__image-wrap--L audio,
  .essay__image.poster__body__block__image-wrap--ML audio,
  .essay__image.poster__body__block__image-wrap--M audio,
  .essay__image.poster__body__block__image-wrap--MS audio,
  .essay__image.poster__body__block__image-wrap--S audio,
  .essay__image.poster__body__block__image-wrap--XS audio {
    margin-top: 20px;
    width: 100%; }
  .essay__image.poster__body__block__image-wrap > span.image-wrapper, .essay__image.poster__body__block__image-wrap .toggle,
  .essay__image.poster__body__block__image-wrap--L > span.image-wrapper,
  .essay__image.poster__body__block__image-wrap--L .toggle,
  .essay__image.poster__body__block__image-wrap--ML > span.image-wrapper,
  .essay__image.poster__body__block__image-wrap--ML .toggle,
  .essay__image.poster__body__block__image-wrap--M > span.image-wrapper,
  .essay__image.poster__body__block__image-wrap--M .toggle,
  .essay__image.poster__body__block__image-wrap--MS > span.image-wrapper,
  .essay__image.poster__body__block__image-wrap--MS .toggle,
  .essay__image.poster__body__block__image-wrap--S > span.image-wrapper,
  .essay__image.poster__body__block__image-wrap--S .toggle,
  .essay__image.poster__body__block__image-wrap--XS > span.image-wrapper,
  .essay__image.poster__body__block__image-wrap--XS .toggle {
    display: block;
    margin: 0 auto; }
    .essay__image.poster__body__block__image-wrap > span.image-wrapper img, .essay__image.poster__body__block__image-wrap > span.image-wrapper video, .essay__image.poster__body__block__image-wrap > span.image-wrapper audio, .essay__image.poster__body__block__image-wrap .toggle img, .essay__image.poster__body__block__image-wrap .toggle video, .essay__image.poster__body__block__image-wrap .toggle audio,
    .essay__image.poster__body__block__image-wrap--L > span.image-wrapper img,
    .essay__image.poster__body__block__image-wrap--L > span.image-wrapper video,
    .essay__image.poster__body__block__image-wrap--L > span.image-wrapper audio,
    .essay__image.poster__body__block__image-wrap--L .toggle img,
    .essay__image.poster__body__block__image-wrap--L .toggle video,
    .essay__image.poster__body__block__image-wrap--L .toggle audio,
    .essay__image.poster__body__block__image-wrap--ML > span.image-wrapper img,
    .essay__image.poster__body__block__image-wrap--ML > span.image-wrapper video,
    .essay__image.poster__body__block__image-wrap--ML > span.image-wrapper audio,
    .essay__image.poster__body__block__image-wrap--ML .toggle img,
    .essay__image.poster__body__block__image-wrap--ML .toggle video,
    .essay__image.poster__body__block__image-wrap--ML .toggle audio,
    .essay__image.poster__body__block__image-wrap--M > span.image-wrapper img,
    .essay__image.poster__body__block__image-wrap--M > span.image-wrapper video,
    .essay__image.poster__body__block__image-wrap--M > span.image-wrapper audio,
    .essay__image.poster__body__block__image-wrap--M .toggle img,
    .essay__image.poster__body__block__image-wrap--M .toggle video,
    .essay__image.poster__body__block__image-wrap--M .toggle audio,
    .essay__image.poster__body__block__image-wrap--MS > span.image-wrapper img,
    .essay__image.poster__body__block__image-wrap--MS > span.image-wrapper video,
    .essay__image.poster__body__block__image-wrap--MS > span.image-wrapper audio,
    .essay__image.poster__body__block__image-wrap--MS .toggle img,
    .essay__image.poster__body__block__image-wrap--MS .toggle video,
    .essay__image.poster__body__block__image-wrap--MS .toggle audio,
    .essay__image.poster__body__block__image-wrap--S > span.image-wrapper img,
    .essay__image.poster__body__block__image-wrap--S > span.image-wrapper video,
    .essay__image.poster__body__block__image-wrap--S > span.image-wrapper audio,
    .essay__image.poster__body__block__image-wrap--S .toggle img,
    .essay__image.poster__body__block__image-wrap--S .toggle video,
    .essay__image.poster__body__block__image-wrap--S .toggle audio,
    .essay__image.poster__body__block__image-wrap--XS > span.image-wrapper img,
    .essay__image.poster__body__block__image-wrap--XS > span.image-wrapper video,
    .essay__image.poster__body__block__image-wrap--XS > span.image-wrapper audio,
    .essay__image.poster__body__block__image-wrap--XS .toggle img,
    .essay__image.poster__body__block__image-wrap--XS .toggle video,
    .essay__image.poster__body__block__image-wrap--XS .toggle audio {
      width: 100%; }

.poster__body__block__image-wrap--XS > span.image-wrapper, .poster__body__block__image-wrap--XS .toggle {
  max-width: 400px !important;
  width: 60%; }

.poster__body__block__image-wrap--S > span.image-wrapper, .poster__body__block__image-wrap--S .toggle {
  max-width: 645px !important;
  width: 70%; }

.poster__body__block__image-wrap--MS > span.image-wrapper, .poster__body__block__image-wrap--MS .toggle {
  max-width: 930px !important;
  width: 80%; }

.poster__body__block__image-wrap--ML > span.image-wrapper, .poster__body__block__image-wrap--ML .toggle {
  max-width: 1000px !important;
  width: 90%; }

.poster__body__block__image-wrap--L > span.image-wrapper, .poster__body__block__image-wrap--L .toggle {
  max-width: 1400px !important;
  width: 100%; }

.poster__body__block__image-wrap--L {
  width: 100%;
  padding: 0 50px; }
  .poster__body__block__image-wrap--L > span.image-wrapper, .poster__body__block__image-wrap--L .toggle {
    display: block;
    margin: 0 auto;
    width: 100%; }
    .poster__body__block__image-wrap--L > span.image-wrapper img, .poster__body__block__image-wrap--L .toggle img {
      width: 100%; }

.poster__body__block__image-wrap--ML {
  width: 100%;
  padding: 0 50px; }
  .poster__body__block__image-wrap--ML > span.image-wrapper, .poster__body__block__image-wrap--ML .toggle {
    display: block;
    margin: 0 auto;
    width: 85%; }
    .poster__body__block__image-wrap--ML > span.image-wrapper img, .poster__body__block__image-wrap--ML .toggle img {
      width: 100%; }

.break-align {
  width: 100%;
  padding: 0 20px; }
  .break-align .poster__body__block__image-wrap,
  .break-align .poster__body__block__image-wrap--L,
  .break-align .poster__body__block__image-wrap--ML,
  .break-align .poster__body__block__image-wrap--M,
  .break-align .poster__body__block__image-wrap--S,
  .break-align .poster__body__block__image-wrap--XS {
    width: 100%;
    padding-left: 0;
    max-width: none; }
    .break-align .poster__body__block__image-wrap > span.image-wrapper, .break-align .poster__body__block__image-wrap .toggle,
    .break-align .poster__body__block__image-wrap--L > span.image-wrapper,
    .break-align .poster__body__block__image-wrap--L .toggle,
    .break-align .poster__body__block__image-wrap--ML > span.image-wrapper,
    .break-align .poster__body__block__image-wrap--ML .toggle,
    .break-align .poster__body__block__image-wrap--M > span.image-wrapper,
    .break-align .poster__body__block__image-wrap--M .toggle,
    .break-align .poster__body__block__image-wrap--S > span.image-wrapper,
    .break-align .poster__body__block__image-wrap--S .toggle,
    .break-align .poster__body__block__image-wrap--XS > span.image-wrapper,
    .break-align .poster__body__block__image-wrap--XS .toggle {
      max-width: 930px !important;
      margin: 0 0 0 180px; }

.break-align .poster__body__block__image-wrap--S {
  width: 100%;
  margin: 0 auto 22px auto; }
  .break-align .poster__body__block__image-wrap--S > span.image-wrapper, .break-align .poster__body__block__image-wrap--S .toggle {
    max-width: 645px !important;
    width: 70%; }

.break-align .poster__body__block__image-wrap--MS {
  width: 100%;
  margin: 0 auto 22px auto; }
  .break-align .poster__body__block__image-wrap--MS > span.image-wrapper, .break-align .poster__body__block__image-wrap--MS .toggle {
    max-width: 930px !important;
    width: 80%; }

.poster__body__section .poster__body__text {
  padding-left: 500px;
  max-width: 1500px;
  padding-right: 50px;
  margin: 18px auto; }

.poster__body__text.is-blockquote {
  padding-left: 640px;
  padding-right: 120px;
  padding-top: 0px;
  padding-bottom: 0px; }
  .poster__body__text.is-blockquote blockquote {
    display: inline;
    margin: 0; }
  .poster__body__text.is-blockquote .poster__body__reference {
    margin-left: -4px; }

.poster__body__section--content {
  max-width: 1500px;
  margin: 0 auto;
  position: relative;
  z-index: 10; }
  .poster__body__section--content .poster__body__text {
    padding-left: 0;
    padding-right: 50px; }

.poster__body__section--content .poster__body__text {
  padding-left: 0; }

.poster__body__section {
  display: flex; }
  .poster__body__section p {
    font-family: "LibreBaskerville";
    padding-left: 500px;
    line-height: 2; }
  .poster__body__section .poster__body__text span {
    font-family: "LibreBaskerville";
    line-height: 2; }
  .poster__body__section .fq-section__description {
    font-family: "Gridnik"; }
    .poster__body__section .fq-section__description span {
      font-family: "Gridnik";
      line-height: 1.2 !important; }
      .poster__body__section .fq-section__description span span {
        line-height: 1.2 !important; }
  .poster__body__section ul li {
    list-style: initial; }
  .poster__body__section .border-block {
    padding: 30px;
    border: 1px solid black;
    margin: 20px; }
    .poster__body__section .border-block h6 {
      font-family: "LibreBaskerville";
      font-size: 20px;
      margin-bottom: 20px; }
    .poster__body__section .border-block li {
      margin: 10px 0;
      margin-left: 50px; }

.poster__body__credits {
  display: inline;
  line-height: 1.2 !important; }
  .poster__body__credits span {
    font-family: "LibreBaskerville" !important;
    margin: 0 !important;
    max-width: none;
    height: auto !important; }

.poster__body__credits__caption {
  display: inline; }
  .poster__body__credits__caption span, .poster__body__credits__caption a {
    font-family: "LibreBaskerville" !important;
    font-size: 12px !important;
    line-height: 1.2 !important; }

.poster__body__credits__credit,
.poster__header__credits__credit {
  display: block;
  text-align: right;
  font-family: "LibreBaskerville" !important;
  font-size: 11px !important;
  line-height: 1.2 !important;
  margin-bottom: 8px;
  width: 100%; }
  .poster__body__credits__credit .poster__body__credits__caption span, .poster__body__credits__credit .poster__body__credits__caption a,
  .poster__header__credits__credit .poster__body__credits__caption span,
  .poster__header__credits__credit .poster__body__credits__caption a {
    font-size: 11px !important; }
  .poster__body__credits__credit .reference-link,
  .poster__header__credits__credit .reference-link {
    text-align: left !important; }
    .poster__body__credits__credit .reference-link .hover-caption,
    .poster__header__credits__credit .reference-link .hover-caption {
      left: auto;
      right: 10px; }

.poster__body__credits__caption {
  margin-right: 3px; }

.poster__body__references, .poster__body__acknowledgments {
  font-size: 14px;
  border-top: 1px solid #999;
  padding: 30px 30px; }
  .poster__body__references li, .poster__body__acknowledgments li {
    font-family: "LibreBaskerville";
    margin: 12px 0; }

.poster__body__references ul {
  padding: 30px; }

.poster__body__references__button {
  width: 100%;
  text-align: center;
  cursor: pointer; }

.poster__body__references__back {
  cursor: pointer; }

.poster__body__section--content p {
  padding-left: 0; }

.poster__footer {
  height: 100vh;
  position: relative; }
  .poster__footer * {
    color: #fff; }

.poster__footer__credit {
  position: absolute;
  top: 12px;
  right: 12px; }

.poster__footer__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.poster__footer__copy {
  font-size: 20px;
  font-size: 28px;
  font-weight: 500; }

.poster__footer__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(122, 122, 122, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; }

.poster__footer__revert {
  background: #fafafa;
  color: black;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 8px 40px;
  font-weight: 500;
  width: 400px;
  margin: 40px auto;
  font-size: 20px; }

.poster__footer__explore {
  transform: translateY(100px); }

#pinContainer {
  height: 75vh;
  width: 100vw; }

.poster__video {
  width: 100%; }

.poster__media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform-origin: left 0px;
  object-fit: cover;
  height: 100%; }

.poster__media__image {
  width: 100%; }

.poster__media__image.height {
  width: auto;
  height: 100%;
  display: block;
  margin: 0 auto; }

.poster__media__audio {
  position: absolute;
  bottom: 0;
  padding: 5px;
  width: 100%; }
  .poster__media__audio audio {
    width: 100%;
    height: 26px; }

ol {
  max-width: 100%;
  word-break: break-word; }

#fq-refs ol {
  list-style-type: lower-roman; }

.sidebar-poem {
  margin-top: 12px;
  margin-left: 40px;
  font-family: adobe-garamond-pro !important;
  display: inline-block;
  white-space: nowrap; }

.sidebar-poem-title {
  font-size: 1.3em;
  font-family: inherit;
  text-align: center; }

.sidebar-poem-author {
  font-size: 1.1em;
  margin-bottom: 26px;
  font-family: inherit;
  text-align: center; }

.sidebar-poem-text {
  font-size: 1.1em;
  line-height: 1.6em;
  white-space: pre-wrap;
  margin-bottom: 20px;
  font-family: inherit; }

.full-poem {
  margin-top: 12px;
  margin-left: 59px;
  font-family: adobe-garamond-pro;
  display: inline-block; }

.full-poem-author {
  font-family: inherit;
  font-size: 1.1em;
  margin-bottom: 26px;
  text-align: center; }

.full-poem-title {
  font-size: 1.3em;
  font-family: inherit;
  text-align: center;
  line-height: 1.5; }

.full-poem-text {
  font-size: 1.1em;
  font-family: inherit;
  line-height: 1.6em;
  margin-left: 60px;
  white-space: pre-wrap; }

.page--poster.fixed-scrolling {
  height: 100vh;
  overflow: hidden; }

.poster__body__fq .poster__body__credits__credit p, .poster__body__fq .poster__body__credits__credit span, .poster__body__fq .poster__body__credits__credit .reference-link {
  font-size: 14px !important;
  font-family: "Gridnik" !important; }

.poster__body__fq .poster__body__credits__caption p, .poster__body__fq .poster__body__credits__caption span, .poster__body__fq .poster__body__credits__caption .reference-link {
  font-size: 14px !important;
  font-family: "Gridnik" !important; }

.cd__content-wrap .poster__body__credits span {
  font-family: "circe-slab-b" !important;
  font-size: 14px !important; }

.cd__content-wrap .poster__body__credits__caption span, .cd__content-wrap .poster__body__credits__caption a {
  font-family: "circe-slab-b" !important; }

.cd__content-wrap .poster__body__credits__credit,
.cd__content-wrap .poster__header__credits__credit {
  font-family: "circe-slab-b" !important; }

.s-i__graph {
  width: 100vw;
  min-height: 100vh;
  position: relative; }

.graph__underlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: .8;
  filter: blur(1px); }

.s-i .reading-room {
  height: auto !important; }

@keyframes fade-slide {
  0% {
    opacity: 0; }
  20% {
    opacity: 1; }
  60% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.underlay__swarm, .underlay__critter {
  position: absolute;
  animation: fade-slide 10s linear;
  animation-fill-mode: forwards; }

.underlay-tr {
  transition: transform 40s linear; }

.underlay-hide {
  transition: opacity .8s ease-out;
  opacity: 0; }

.underlay-tr-sub {
  transition: transform 15s linear; }

.underlay__swarm__name {
  font-weight: 500;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px; }

.underlay__swarm__critters h6 {
  position: absolute;
  white-space: nowrap; }

.graph-header__label {
  font-size: 16px;
  transition: opacity .15s ease-in-out; }

.graph-header__label--hide {
  opacity: .1; }

.graph-header__label--active {
  opacity: 1; }

.graph-header--landscapes {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  padding: 20px 100px 20px 200px;
  display: flex;
  justify-content: space-around; }
  .graph-header--landscapes .graph-header__label {
    font-weight: 500;
    text-transform: uppercase;
    color: #666; }
    .graph-header--landscapes .graph-header__label:hover {
      color: #000; }

.graph-header--infrastructures {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around; }
  .graph-header--infrastructures .graph-header__label {
    text-transform: uppercase;
    font-weight: 500;
    color: #666; }
    .graph-header--infrastructures .graph-header__label:hover {
      color: #000; }

.graph-header--feral-qualities {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: right; }

.graph__critters {
  padding: 100px 200px;
  column-count: 3; }

.graph__critter {
  font-size: 14px;
  margin: 6px 0;
  margin-top: 0;
  transition: opacity .15s ease-in-out; }
  .graph__critter p {
    color: #666;
    font-size: 14px;
    line-height: 1.35;
    margin: 0; }

.graph__critter--hide {
  opacity: .1; }

.graph__critter--active {
  opacity: 1; }

.s-i__critter-catalogue {
  max-width: 1200px;
  margin: 100px auto; }

.s-i__critter-row {
  display: flex;
  margin-bottom: 30px;
  padding: 0 20px;
  justify-content: space-between; }

.critter-catalogue__critter {
  width: 96px;
  position: relative;
  text-align: center; }

.critter-catalogue__critter--visited {
  opacity: 1; }
  .critter-catalogue__critter--visited .critter-catalogue__critter__image {
    opacity: 1; }

.critter-catalogue__critter__image {
  width: 100%;
  opacity: .25; }

.critter-catalogue__critter__name * {
  font-size: 14px;
  font-weight: 400 !important; }

.critter-catalogue__author {
  margin-top: 8px;
  font-size: 10px; }

.critter-catalogue__details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 40px; }

.critter-catalogue__details__section {
  font-size: 24px;
  padding: 12px;
  border-top: 1px solid black;
  height: 33.33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .critter-catalogue__details__section:first-of-type {
    border-top: none; }

.s-i__critter-catalogue__section {
  width: 50%;
  margin: 40px auto;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid-column; }
  .s-i__critter-catalogue__section:first-of-type {
    margin-top: 0; }

.s-i__critter-catalogue__section-header {
  margin-bottom: 12px;
  font-size: 14px;
  text-transform: uppercase; }

.s-i {
  padding-bottom: 100px; }

.s-i__back {
  position: fixed;
  top: 20px;
  left: 12px;
  z-index: 10;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: #fafafa;
  border-radius: 3px;
  padding: 6px 12px;
  text-decoration: none; }

@media (max-width: 1200px) {
  .graph-header--landscapes {
    padding: 10px 100px; }
  .graph-header--feral-qualities {
    padding: 60px 10px; }
  .graph-header--infrastructures {
    padding: 60px 10px; }
  .graph__critters {
    padding: 65px 200px; }
  .s-i__critter-catalogue__section {
    margin: 24px auto; }
  .graph__critter {
    margin: 4px 0;
    margin-top: 0; }
  .graph-header__label {
    font-size: 16px; } }

.critter-graph-item {
  position: relative; }
  .critter-graph-item .authors {
    position: absolute;
    top: -60%;
    left: 80%;
    min-width: 180px;
    font-size: 14px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    background: #fafafa;
    border-radius: 3px;
    padding: 2px 12px;
    display: none; }
    .critter-graph-item .authors span {
      display: block;
      margin: 6px 0px;
      color: #666; }
      .critter-graph-item .authors span:hover {
        color: #000; }
  .critter-graph-item:hover .authors {
    display: block;
    z-index: 10; }
  .critter-graph-item:hover .graph__critter p {
    color: #000; }
