@keyframes fadeIn{
	from{
		opacity: 0;
	}

	to{
		opacity: 1;
	}
}

#Surface{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	list-style: none;
	box-sizing: border-box;
	opacity: 0;
	animation: fadeIn ease-out 2s 0.5s;
	animation-fill-mode: forwards;
}


ul, li{
	padding: 0;
	margin: 0;
	padding-left: 0;
	margin-left: 0;
}

#Surface.paused *{
	animation-play-state: paused !important;
}